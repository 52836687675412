
import { computed, defineComponent, reactive, ref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { numberSeparators } from "@/graphql/utils/utils";
import { useValidation } from "vue3-form-validation";

export default defineComponent({
  name: "PaymentReceipt",
  props: [
    "resume",
    "ticket",
    "print",
    "customer",
    "discount",
    "visible",
    "loading",
  ],
  emits: ["validate", "update:print", "update:visible"],
  setup(props, { emit }) {
    const dialogChange = reactive({
      show: false,
      value: 0,
    });
    const { form, validateFields, resetFields } = useValidation({
      method: {
        $value: 0,
      },
      received: {
        $value: 0,
      },
      note: {
        $value: "",
      },
    });
    const summary = computed(() => {
      const cost = props.resume.total - props.discount;
      const received = props.resume.receivedSum + form.received.$value;
      const remain =
        form.method.$value !== 3 ? (received < cost ? cost - received : 0) : 0;
      return {
        change: received > cost ? received - cost : 0,
        remain: Number(remain.toFixed(activeActivity.value.decimalNumber)),
        received,
        cost,
      };
    });
    function setReceived() {
      form.received.$value =
        props.resume.total - props.resume.receivedSum - props.discount;
    }
    function onActivePrinter(value: boolean) {
      setReceived();
      if (value === null) {
        emit("update:print", localStorage.getItem("printer") === "on");
      } else {
        if (value) localStorage.setItem("printer", "on");
        else localStorage.removeItem("printer");
        emit("update:print", value);
      }
    }
    function onValidate() {
      dialogChange.value = summary.value.change;
      validateFields().then((input) => {
        if (dialogChange.value > 0) {
          dialogChange.show = true;
          emit("update:visible", false);
        }
        let status = 1;
        if (props.ticket?.status === 1) {
          // if ticket was initially credit
          status = summary.value.remain === 0 ? 3 : 1;
        } else if (summary.value.remain === 0) status = 0;
        emit("validate", {
          status,
          payment:
            input.method !== 4
              ? {
                  ...input,
                  change: summary.value.change,
                }
              : null,
        });
      });
    }
    return {
      dialogChange,
      form,
      summary,
      resetFields,
      activeActivity,
      numberSeparators,
      onActivePrinter,
      setMethod: (value: number) => {
        if (value === 3) {
          form.received.$value = summary.value.remain;
        } else if (value === 4) form.received.$value = 0;
        else if (value !== null && !form.received.$value) setReceived();
        if (value !== null) form.method.$value = value;
      },
      control: computed(() => {
        const isCredit = form.method.$value === 4 || props.ticket?.status === 1;
        const creditWithNoCustomer = !props.customer && isCredit;
        const gap =
          [0, 1, 2, 5].includes(form.method.$value) &&
          !isCredit &&
          summary.value.remain > 0;
        return {
          disable: creditWithNoCustomer || gap,
          creditWithNoCustomer,
        };
      }),
      onValidate,
    };
  },
});
